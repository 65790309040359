<template>
    <div class="main-container">
        <div class="head-row">
            <div class="selector-container">
                <HtFormSelector
                    id="custom-filter"
                    v-model="filters.delegationType"
                    name="custom-filter"
                    :options="[
                        {label: translate('Outgoing Delegations'), value: 'fromMe'},
                        {label: translate('Incomming Delegations'), value: 'toMe'}
                    ]"
                    :placeholder="translate('None')"
                    is-single
                    track-by="value"
                    specific-key="value"
                    label-options="label"
                    :allow-empty="false"
                    @input="loadData"
                >
                    <template #prepend>
                        <FontAwesomeIcon
                            :icon="['far', 'filter']"
                        />
                    </template>
                </HtFormSelector>
            </div>
            <div>
                <HtButton
                    v-if="!(filters.delegationType === 'fromMe' && !delegations.length)"
                    :loading="loading"
                    :disabled="loading"
                    @click.native="openDelegationForm"
                >
                    <t>Delegate</t>
                </HtButton>
            </div>
        </div>
        <div
            class="table"
        >
            <div
                class="table-scrollable-container"
            >
                <HTable
                    v-if="delegations.length || loading"
                    :use-new-style="true"
                    :columns="typedColumns"
                    :data="delegations"
                    :loading="loading"
                    @on-row-click="openEditModal"
                >
                    <template #cell(role)="data">
                        {{ data.value.is_heyteam ? translate(data.value.alias) : data.value.alias }}
                    </template>

                    <template #cell(from_user)="data">
                        <HTableCellUser
                            v-if="data.value"
                            :user="data.value"
                        />
                    </template>

                    <template #cell(to_user)="data">
                        <HTableCellUser
                            v-if="data.value"
                            :user="data.value"
                        />
                    </template>

                    <template #cell(employees)="data">
                        {{ data.value.length + ' ' + translate('Collaborators') }}
                    </template>

                    <template #cell(start_date)="data">
                        {{ new Date(data.value).toLocaleDateString() }}
                    </template>

                    <template #cell(end_date)="data">
                        {{ data.value ? new Date(data.value).toLocaleDateString() : "" }}
                    </template>
                </HTable>
                <div
                    v-else-if="filters.delegationType === 'fromMe'"
                    class="empty-state"
                >
                    <span class="empty-message">{{ translate('Delegate tasks to another user.') }}</span>
                    <HtButton
                        :loading="loading"
                        :disabled="loading"
                        @click.native="openDelegationForm"
                    >
                        <t>Delegate</t>
                    </HtButton>
                </div>
                <div
                    v-else
                    class="empty-state"
                >
                    <span>{{ translate('No incomming delegation found.') }}</span>
                </div>
            </div>
            <div>
                <HtPagination
                    v-model="page"
                    :last-page="lastPage"
                    class="pagination"
                    @input="loadData"
                />
            </div>
        </div>
        <Modalable
            ref="modalable"
            class="modalable-1 medium"
            :mode="2"
            @onClose="$emit('on-close-modalable')"
        >
            <DelegationForm
                :user-id="companyUserId"
                :delegation="selectedDelegation"
                @saved="loadData"
            />
        </Modalable>
    </div>
</template>

<script>
import { HTable, HTableCellUser } from '@/components/globals/table';
import api from '@/store/api';
import HtPagination from '@/components/globals/HtPagination.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtButton from '@/components/globals/HtButton.vue';
import Modalable from '@/components/globals/Modalable.vue';
import DelegationForm from './activities/DelegationForm.vue';

export default {
    name: 'DelegationsActivities',
    components: {
        HTable,
        HtPagination,
        HtFormSelector,
        HtButton,
        Modalable,
        DelegationForm,
        HTableCellUser,
    },
    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            delegations: [],
            page: 1,
            lastPage: 1,
            selectedDelegation: null,
            filters: {
                delegationType: 'fromMe',
            },
            columns: [
                {
                    label: this.translate('Role'),
                    iconClass: ['far', 'user'],
                    key: 'role',
                    sortable: true,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('From User'),
                    iconClass: ['far', 'user'],
                    key: 'from_user',
                    sortable: true,
                    thStyle: {
                        width: '350px',
                    },
                    delegationType: 'toMe',
                },
                {
                    label: this.translate('To User'),
                    iconClass: ['far', 'user'],
                    key: 'to_user',
                    sortable: true,
                    thStyle: {
                        width: '350px',
                    },
                    delegationType: 'fromMe',
                },
                {
                    label: this.translate('Employees'),
                    iconClass: ['far', 'chart-network'],
                    key: 'employees',
                    sortable: false,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('Start at'),
                    iconClass: ['fal', 'calendar-alt'],
                    key: 'start_date',
                    sortable: true,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('End at'),
                    iconClass: ['fal', 'calendar-alt'],
                    key: 'end_date',
                    sortable: true,
                    thStyle: {
                        width: '350px',
                    },
                },
            ],
        };
    },
    computed: {
        typedColumns() {
            const criteria = this.filters.delegationType === 'fromMe' ? 'toMe' : 'fromMe';
            return this.columns.filter((column) => column.delegationType !== criteria);
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loading = true;
            try {
                const response = await api.user.delegations.get(this.getData());
                this.delegations = response.data.data;
                this.lastPage = response.data.last_page;
            } catch (e) {
            } finally {
                this.loading = false;
            }
        },
        getData() {
            return {
                page: this.page,
                perPage: 15,
                filters: {
                    delegationType: this.filters.delegationType,
                },
                user_id: this.companyUserId,
            };
        },
        openDelegationForm() {
            this.selectedDelegation = null;
            this.$refs.modalable.open();
        },
        openEditModal(rowClicked) {
            this.selectedDelegation = {
                id: rowClicked.id,
                role_id: rowClicked.role.id,
                to_user: rowClicked.to_user,
                employees: rowClicked.employees,
                start_date: rowClicked.start_date,
                end_date: rowClicked.end_date,
            };
            this.$refs.modalable.open();
        },
    },
};
</script>

<style lang="scss" scoped>
.head-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .title {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        gap: 8px;
    }

    .search-container, .extra-action-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        margin-left: auto;
    }
}
.table {
    margin-bottom: 80px;
    margin-top: 30px;

    .table-scrollable-container{
        overflow-y: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        table {
            width: 100%;
            white-space: nowrap;
            table-layout: auto !important;
        }
    }
}

.table-button {
    display: block;
}

.empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-message {
        margin-bottom: 1rem;
    }
}
</style>
