<template>
    <div class="form-container">
        <HtFormGroup>
            <HtFormSelector
                id="roleId"
                v-model="roleId"
                name="roleId"
                :options="roleOptions"
                :label="translate('Role')"
                :placeholder="translate('Select a role')"
                :required="true"
                :show-optional="false"
                :show-asterisk="true"
                label-options="name"
                track-by="id"
                specific-key="id"
                :allow-empty="false"
                :is-single="true"
                :disabled="delegationStarted"
                @input="updateSupervisedEmployees"
            />
            <span
                v-if="validationErrors.role_id"
                class="error-message"
            ><t>{{ validationErrors.role_id[0] }}</t></span>
        </HtFormGroup>
        <HtFormGroup>
            <HtFormUserSelector
                id="toUserId"
                v-model="toUserId"
                v-validate.disable="'required'"
                name="toUserId"
                :label="translate('Target')"
                :data-vv-as="translate('Select the target user')"
                :allow-empty="false"
                :show-asterisk="true"
                :show-optional="false"
                :is-single="true"
                track-by="company_user_id"
                specific-key="company_user_id"
                from-api
                :options="selectedToUserOptions"
                :errors="validationErrors.toUserId"
                :disabled="delegationStarted"
                :excluded-user-ids="excludedToUserIds"
                @input="updateSupervisedEmployees"
            />
            <span
                v-if="validationErrors.to_user_id"
                class="error-message"
            ><t>{{ validationErrors.to_user_id[0] }}</t></span>
        </HtFormGroup>
        <HtFormGroup>
            <HtFormSelector
                id="employeeIds"
                v-model="employeeIds"
                name="employeeIds"
                :options="supervisedUsers"
                :label="translate('Collaborators')"
                :placeholder="translate('Select involved collaborators')"
                :required="true"
                :show-asterisk="true"
                :show-optional="false"
                label-options="fullname"
                track-by="id"
                specific-key="id"
                :disabled="delegationDone"
                :allow-empty="false"
                :is-single="false"
                :enable-selector="true"
                :max="100"
            />
            <span style="display: block; margin-top: -.5em; text-align: right;"><span>{{ employeeIds.length }} / 100 </span><t>Selected collaborators</t></span>
            <span
                v-if="validationErrors.employee_ids"
                class="error-message"
            ><t>{{ validationErrors.employee_ids[0] }}</t></span>
        </HtFormGroup>
        <HtFormGroup>
            <div class="dates-container">
                <div>
                    <HtFormFlatPickr
                        id="startDate"
                        v-model="startDate"
                        name="startDate"
                        :label="translate('Start date')"
                        :placeholder="translate('Select a start date')"
                        :required="true"
                        :show-asterisk="true"
                        :show-optional="false"
                        :disabled="delegationStarted"
                        :min-date="(delegationStarted ? null : new Date().toISOString().split('T')[0])"
                    />
                    <span
                        v-if="validationErrors.start_date"
                        class="error-message"
                    ><t>{{ validationErrors.start_date[0] }}</t></span>
                </div>
                <div>
                    <HtFormFlatPickr
                        id="endDate"
                        v-model="endDate"
                        name="endDate"
                        :label="translate('End date')"
                        :placeholder="translate('Select an end date')"
                        :min-date="delegationStarted ? new Date().toISOString().split('T')[0] : startDate"
                        :disabled="delegationDone"
                    />
                    <span
                        v-if="validationErrors.end_date"
                        class="error-message"
                    ><t>{{ validationErrors.end_date[0] }}</t></span>
                </div>
            </div>
        </HtFormGroup>
        <div class="modal-actions">
            <HtButton
                v-if="!delegationDone"
                :loading="loading"
                :disabled="loading"
                @click.native="save"
            >
                <t>Delegate</t>
            </HtButton>
            <HtButton
                v-if="delegation && !delegationDone"
                :loading="loading"
                :disabled="loading"
                type="secondary"
                @click.native="remove"
            >
                <t v-if="delegationStarted">
                    Cancel
                </t>
                <t v-else>
                    Delete
                </t>
            </HtButton>
        </div>
    </div>
</template>

<script>
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtFormUserSelector from '@/components/globals/Selectors/HtFormUserSelector.vue';
import api from '@/store/api';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'DelegationForm',
    components: {
        HtFormGroup,
        HtFormSelector,
        HtFormUserSelector,
        HtFormFlatPickr,
        HtButton,
    },
    inject: ['modal'],
    props: {
        userId: {
            type: Number,
            required: true,
        },
        delegation: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            loading: false,
            id: this.delegation ? this.delegation.id : null,
            roleId: this.delegation ? this.delegation.role_id : null,
            toUserId: this.delegation ? this.delegation.to_user.id : null,
            employeeIds: this.delegation ? this.delegation.employees.map((employee) => employee.employee_id) : [],
            supervisedUsers: this.delegation ? this.delegation.employees.map((employee) => employee.employee) : [],
            startDate: this.delegation ? this.delegation.start_date : null,
            endDate: this.delegation ? this.delegation.end_date : null,
            validationErrors: [],
            selectedToUserOptions: this.delegation ? [{ ...this.delegation.to_user, company_user_id: this.delegation.to_user.id }] : [],
        };
    },
    computed: {
        delegationStarted() {
            return !!this.delegation && new Date(this.delegation.start_date) <= new Date();
        },
        delegationDone() {
            return !!this.delegation && (this.delegation.end_date && new Date(this.delegation.end_date) <= new Date());
        },
        roleOptions() {
            return this.$store.getters['roles/sortedUserRoles']
                .filter((role) => role.alias !== 'Employee')
                .map((role) => ({
                    id: role.id,
                    name: role.is_heyteam ? this.translate(role.alias) : role.alias,
                }));
        },
        excludedToUserIds() {
            return [this.userId, ...this.employeeIds];
        },
    },
    created() {
        this.modal.setTitle(this.translate('Delegate a role'));
        this.$store.dispatch('roles/fetchUserRoles', { userId: this.userId, force: true });
        if (this.delegation) {
            this.updateSupervisedEmployees();
        }
    },
    methods: {
        async updateSupervisedEmployees() {
            this.validationErrors = [];
            this.supervisedUsers = (await api.user.delegations.getSupervised({
                userId: this.userId,
                roleId: this.roleId,
            })).data.data.filter((employee) => employee.id !== this.toUserId);

            if (this.delegation) {
                this.delegation.employees.forEach((employee) => {
                    if (!this.supervisedUsers.find((supervised) => supervised.id === employee.employee_id)) {
                        this.supervisedUsers.push(employee.employee);
                    }
                });
            }
        },
        async save() {
            if (this.loading) return;
            this.validationErrors = [];
            this.loading = true;
            try {
                if (this.delegation && this.delegation.id) {
                    await api.user.delegations.update({
                        id: this.id,
                        from_user_id: this.userId,
                        role_id: this.roleId,
                        to_user_id: this.toUserId,
                        employee_ids: this.employeeIds,
                        start_date: this.startDate,
                        end_date: this.endDate,
                    });
                } else {
                    await api.user.delegations.create({
                        from_user_id: this.userId,
                        role_id: this.roleId,
                        to_user_id: this.toUserId,
                        employee_ids: this.employeeIds,
                        start_date: this.startDate,
                        end_date: this.endDate,
                    });
                }

                this.$emit('saved');
                this.modal.close();
            } catch (e) {
                this.validationErrors = e.response.data.errors ?? [];
            } finally {
                this.loading = false;
            }
        },
        async remove() {
            if (this.loading) return;
            this.loading = true;
            try {
                await api.user.delegations.delete({
                    from_user_id: this.userId,
                    delegation_id: this.delegation.id,
                });
                this.$emit('saved');
                this.modal.close();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dates-container {
    display: grid;;
    grid-template-columns: 2fr 2fr;
    gap: 1rem;
}
.error-message {
    color: red;
    font-style: italic;
    margin-top: -.5rem;
}
</style>
